import { useState, useEffect } from 'react';
import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { Input as Primitive } from 'components/primitives/form';

const displayValueRegex = /^-?\d*$/;
const componentBuilder = ({ field, form, ...rest }) => {
  const [displayValue, setDisplayValue] = useState(field.value || '');

  useEffect(() => {
    if (field.value != null && field.value !== +displayValue)
      setDisplayValue(field.value);
  }, [field.value]);

  const setValue = newValue => {
    setDisplayValue(newValue);
    form.setFieldValue(field.name, newValue === '' || newValue === '-' ? newValue : +newValue);
  };

  const onChange = e => {
    const newValue = e.target.value;

    if (!displayValueRegex.test(newValue))
      return;

    setValue(newValue);
  };

  const onPaste = e => {
    e.preventDefault();

    const newValue = e.clipboardData.getData('text')
      .replace(/-/g, (match, offset) => offset ? '' : match) // Remove all non-first '-'.
      .replace(/[^-\d]/g, '');

    setValue(newValue);
  };

  return (
    <Primitive
      {...field}
      {...rest}
      value={displayValue}
      onChange={onChange}
      onPaste={onPaste}
    />
  );
};

// eslint-disable-next-line react/no-multi-comp
const IntegerField = props => (
  <Field
    type="text"
    component={componentBuilder}
    {...props}
  />
);

IntegerField.propTypes = fieldPropTypes;

export default IntegerField;
