exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DescriptionMessage_description-message{color:var(--theme-regularTitle_Color,#333);line-height:1.2;margin-top:.6em}.DescriptionMessage_description-message .DescriptionMessage_icon{margin-right:1.2em}", ""]);

// exports
exports.locals = {
	"description-message": "DescriptionMessage_description-message",
	"descriptionMessage": "DescriptionMessage_description-message",
	"icon": "DescriptionMessage_icon"
};