import { useMemo } from 'react';
import { DateSelect } from 'components/primitives/form';
import createDateField from './createDateField';

const Primitive = props => {
  const { onKeyDown, onBlur, 'aria-invalid': _ariaInvalid, maxDate, ...dateSelectProps } = props;

  const maxDateValue = useMemo(() => {
    if (maxDate)
      return maxDate;

    const currentYear = new Date().getFullYear();
    return new Date(currentYear, 11, 31);
  }, [maxDate]);

  return <DateSelect {...dateSelectProps} maxDate={maxDateValue} />;
};

const DateDropdownField = createDateField(Primitive);
export default DateDropdownField;
