import styles from './Radio.module.scss';
import type { ReactNode } from 'react';

export type Props = {
  className?: string | null;
  children?: ReactNode;
} & Omit<JSX.IntrinsicElements['input'], 'type' | 'className'>;

const Radio = ({ className, children = null, ...attributes }: Props) => (
  // tabIndex is used to prevent focus been set on any parent focusable container which holds label element when clicking on it.
  <label className={`${styles.lbl} ${className || ''}`} tabIndex={-1}>
    <span className={styles.btn}>
      <input type="radio" {...attributes} />
      <ins aria-hidden />
    </span>
    {children}
  </label>
);

export default Radio;
