exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Input_tbx{position:relative}.Input_tbx input{background:var(--theme-input_BackgroundColor,#f2f2f2);border:0;border-bottom-style:solid;border-bottom-width:1px;border-color:var(--theme-input_BottomBorderColor,#c2c2c2);border-radius:3px;box-sizing:border-box;color:var(--theme-input_Color,#333);display:block;height:2.61em;padding:0 1em;text-overflow:ellipsis;width:100%}.Input_tbx input:focus{background-color:var(--theme-input_Focus_BackgroundColor,#fff);border-color:var(--theme-input_Focus_BottomBorderColor,#1f7bc9)}.Input_tbx input[aria-invalid=true]{background-color:var(--theme-input_Error_BackgroundColor,#fcf5f5);border-color:var(--theme-validation_Error_Color,#cb2245)}.Input_tbx ::-webkit-input-placeholder{color:var(--theme-input_Placeholder_Color,#b2b2b2);font-style:italic;max-width:100%;white-space:nowrap}.Input_tbx :-ms-input-placeholder{color:var(--theme-input_Placeholder_Color,#b2b2b2);font-style:italic;max-width:100%;white-space:nowrap}.Input_tbx ::placeholder{color:var(--theme-input_Placeholder_Color,#b2b2b2);font-style:italic;max-width:100%;white-space:nowrap}.row-error .Input_tbx input,.row-error .Input_tbx input:focus{background:var(--theme-input_Error_BackgroundColor,#fcf5f5);border-color:var(--theme-input_Error_BottomBorderColor,#ce8d9b);color:var(--theme-validation_Error_Color,#cb2245)}.row-error .Input_tbx ::-webkit-input-placeholder{color:var(--theme-input_Placeholder_Color,#b2b2b2);font-style:italic}.row-error .Input_tbx :-ms-input-placeholder{color:var(--theme-input_Placeholder_Color,#b2b2b2);font-style:italic}.row-error .Input_tbx ::placeholder{color:var(--theme-input_Placeholder_Color,#b2b2b2);font-style:italic}", ""]);

// exports
exports.locals = {
	"tbx": "Input_tbx"
};