import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { TextArea } from 'components/primitives/form';

const TextAreaField = props => (
  <Field
    as={TextArea}
    {...props}
  />
);

TextAreaField.propTypes = fieldPropTypes;

export default TextAreaField;
