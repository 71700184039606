import { validateForm } from '../helpers';
import { resetCaptcha } from 'behavior/captcha';

export function initFormRef(formRef, formikRef, captchaRef, dispatch) {
  formRef.current = {
    validate: async () => await validateForm(formikRef),
    reset: () => formikRef.current.resetForm(),
    submit: () => formikRef.current.submitForm(),
    get values() {
      return formikRef.current.values;
    },
    set values(values) {
      formikRef.current.setValues(values);
    },
    set errors(errors) {
      if (!errors || !errors.length)
        return;

      const formik = formikRef.current;
      for (const { fieldName, message } of errors) {
        formik.setFieldError(fieldName, message);
        formik.setFieldTouched(fieldName, true, false);
      }
    },
    captcha: {
      reset: () => void (captchaRef.current && dispatch(resetCaptcha(formikRef.current.status.formName))),
    },
  };
}
