import type { CommonProps, Item } from './types';
import { useMemo } from 'react';
import Select from '../Select';
import { getMaxDay } from '../datePicker/helpers';
import { pad } from 'utils/helpers';

type Props = CommonProps & {
  month: number | null;
  year: number | null;
};

const DaySelect = ({
  id,
  name,
  placeholderText,
  month,
  year,
  ...selectProps
}: Props) => {
  const maxDay = useMemo(() => getMaxDay(month, year), [month, year]);

  const items = useMemo(() => {
    const items: Array<Item> = [{ name: placeholderText, value: null }];
    for (let i = 1; i <= maxDay; i++)
      items.push({ name: pad(i, 2), value: i });

    return items;
  }, [maxDay, placeholderText]);

  return (
    <Select
      items={items}
      id={`${id}_day`}
      name={name && `${name}_day`}
      {...selectProps}
    />
  );
};

export default DaySelect;
