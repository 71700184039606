import { sanitizeId } from 'utils/helpers';

export function createFieldId(formName, fieldName) {
  return sanitizeId(`${formName}_${fieldName}`);
}

export function createLabelId(formName, fieldName) {
  return createFieldId(formName, fieldName) + '_lbl';
}

export function createErrorMsgId(formName, fieldName) {
  return createFieldId(formName, fieldName) + '_err';
}

export async function validateForm(formikRef, preserveTouched) {
  const formik = formikRef && formikRef.current;
  if (!formik)
    return true;

  const errors = await formik.validateForm(formik.values);
  if (!Object.keys(errors).length)
    return true;

  if (!preserveTouched)
    formik.setTouched(convertErrorsToTouched(errors, formik.touched), false);

  return false;
}

function convertErrorsToTouched(errors, currentTouched) {
  const touched = { ...currentTouched };
  for (const key of Object.keys(errors))
    touched[key] = getTouchedValue(errors[key], touched[key]);

  return touched;
}

function getTouchedValue(error, currentTouched) {
  if (Array.isArray(error)) {
    const touched = currentTouched ? [...currentTouched] : [];
    for (let i = 0; i < error.length; i++)
      touched[i] = getTouchedValue(error[i]);

    return touched;
  } else if (error instanceof Object)
    return convertErrorsToTouched(error, currentTouched);
  else
    return true;
}
