import { getIn } from 'formik';

export const getPerCountryValidator = (perCountryPatterns, countryFieldName, values, countryId) => {
  let currentCountryId, perCountryValidator;
  if (countryFieldName != null) {
    currentCountryId = getIn(values, countryFieldName);
    if (currentCountryId == null)
      currentCountryId = countryId;
  }

  if (currentCountryId != null)
    perCountryValidator = perCountryPatterns.find(v => v.countryId === currentCountryId);
  if (perCountryValidator == null)
    perCountryValidator = perCountryPatterns.find(v => v.countryId == null);

  return perCountryValidator;
};