import { useRef, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useComputedValue, useCultureName } from 'utils/hooks';
import validators from '../validators';
import useValidationTexts from './useValidationTexts';
import { removeInsiteEditorEncoding } from 'components/visualDesigner/insiteEditor';

const useFieldValidation = (fieldName, fieldTitle, validation, dependencies = {}) => {
  const validators = useFieldValidators(fieldTitle, validation);
  return useCombineValidators(fieldName, validators, dependencies);
};

export default useFieldValidation;

function useFieldValidators(fieldTitle, validation) {
  const validationTexts = useValidationTexts();
  const culture = useCultureName();

  return useComputedValue(() => {
    if (!validation)
      return null;

    const fieldValidators = [];
    for (const [name, params] of Object.entries(validation)) {
      if (!params)
        continue;

      if (name === 'custom' && typeof params === 'function') {
        fieldValidators.push(params);
        continue;
      }

      const validator = validators[name];
      if (!validator) {
        console.error(`Validator '${name}' is not registered.`);
        continue;
      }

      const { factory: validatorFactory, defaultMessageKey } = validator;

      const decodedTitle = removeInsiteEditorEncoding(fieldTitle);
      const validatorParams = { ...params, fieldTitle: decodedTitle, culture };

      if (validatorParams.message === undefined && defaultMessageKey)
        validatorParams.message = validationTexts[defaultMessageKey];

      const fieldValidator = validatorFactory(validatorParams);
      if (fieldValidator)
        fieldValidators.push(fieldValidator);
    }

    return fieldValidators;
  }, [validation, fieldTitle, validationTexts, culture]);
}

function useCombineValidators(fieldName, validators, dependencies) {
  const formikRef = useRef();
  const formik = useFormikContext();

  formikRef.current = formik;

  return useMemo(() => {
    if (!validators || !validators.length)
      return null;

    return async value => {
      for (const validator of validators) {
        const validationResult = await validator(value, fieldName, formikRef.current, dependencies);
        if (validationResult !== undefined)
          return validationResult;
      }
    };
  }, [validators, fieldName, ...Object.values(dependencies)]);
}
