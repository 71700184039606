import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { sanitizeClassName } from 'utils/helpers';

const ErrorMessage = ({ fieldName, className = '', children, ...attributes }) => {
  let errorClassName = `field-validation-error ${className}`;
  if (fieldName)
    errorClassName += ` ${sanitizeClassName(fieldName)}-error`;

  if (!children)
    return <span className={errorClassName} />;

  return (
    <span className={errorClassName} {...attributes}>
      <FontAwesomeIcon icon={faTimesCircle} />
      {children}
    </span>
  );
};

ErrorMessage.propTypes = {
  fieldName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default ErrorMessage;