import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { Input } from 'components/primitives/form';

const PhoneNumberField = props => (
  <Field
    type="tel"
    as={Input}
    {...props}
  />
);

PhoneNumberField.propTypes = fieldPropTypes;

export default PhoneNumberField;
