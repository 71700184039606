import styles from 'components/objects/forms/SanaForm.module.scss';
import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';

const RatingField = ({ fieldName, validate, ...attributes }) => (
  <fieldset className={styles.stars}>
    <Field fieldName={fieldName} validate={validate} {...attributes}>
      {({ field }) => {
        const radios = [];
        const { name, onChange, onBlur } = field;
        const value = +field.value;

        const onMouseOver = e => {
          const currentTarget = e.currentTarget;
          let label = currentTarget;

          do
            label.className = styles.selectedStar;
          while ((label = label.previousElementSibling));

          label = currentTarget;
          while ((label = label.nextElementSibling))
            label.className = '';
        };

        const onMouseLeave = e => {
          const labels = e.currentTarget.parentElement.children;
          for (let i = 0; i < labels.length; i++) {
            const label = labels[i];
            label.className = i + 1 <= value ? styles.selectedStar : '';
          }
        };

        const anyChecked = value > 0;

        for (let i = 1; i <= 5; i++) {
          const className = i <= value ? styles.selectedStar : null;
          const checked = anyChecked && value === i;
          let tabIndex = -1;

          if (checked || (!anyChecked && i === 1))
            tabIndex = 0;

          radios.push((
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <label className={className} key={i}
              onFocus={onMouseOver} onBlur={onMouseLeave}
              onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}
              onMouseDown={e => e.preventDefault()}
            >
              <FontAwesomeIcon icon={solidStar} />
              <input type="radio"
                name={name}
                className="visually-hidden"
                value={i}
                checked={checked}
                tabIndex={tabIndex}
                onChange={onChange}
                onBlur={onBlur}
              />
            </label>
          ));
        }

        return radios;
      }}
    </Field>
  </fieldset>
);

RatingField.propTypes = fieldPropTypes;

export default RatingField;
