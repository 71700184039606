import { validationTextKeys } from '../constants';
import { makeSimpleText } from 'utils/render';
import { useSanaTexts } from 'components/sanaText';
import { useMemo } from 'react';

export default function useValidationTexts() {
  const sanaTexts = useSanaTexts(validationTextKeys, makeSimpleText).texts;

  return useMemo(() => {
    return validationTextKeys.reduce((acc, textKey, index) => {
      const sanaText = sanaTexts[index];
      if (sanaText)
        acc[textKey] = sanaText;

      return acc;
    }, {});
  }, [sanaTexts]);
}
