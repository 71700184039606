import { createElement, useMemo, ComponentType, ReactNode, ChangeEvent, ChangeEventHandler } from 'react';

export type InputChangeHandler = (value: string) => void;

type CommonProps = {
  children?: ReactNode;
};

type ComponentProps = CommonProps & {
  onChange?: ChangeEventHandler | null;
  [K: string]: unknown;
};

type Props = CommonProps & {
  onChange: InputChangeHandler;
  [K: string]: unknown;
};

export default function (Input: ComponentType<ComponentProps>, props: Props) {
  const { onChange, children, ...otherProps } = props;
  const onValueChanged = useMemo(() => onChange ? (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onChange(e.target.value) : null, [onChange]);

  return createElement(Input, { onChange: onValueChanged, ...otherProps }, children);
}
