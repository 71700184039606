import styles from './Input.module.scss';
import type { Ref } from 'react';

export type Props = {
  className?: string | null;
  innerRef?: Ref<HTMLDivElement> | null;
} & Omit<JSX.IntrinsicElements['input'], 'className'>;

const Input = ({ type, className, innerRef = null, value, ...attributes }: Props) => (
  <div className={`${styles.tbx} ${className || ''}`} ref={innerRef} role="presentation">
    <input type={type} value={value === null ? '' : value} {...attributes} />
  </div>
);

export default Input;
