import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Input from './Input';
import { getMaxDay } from '../helpers';

const DayInput = ({ month, year, ...inputProps }) => {
  const maxDay = useMemo(() => getMaxDay(month, year), [month, year]);

  return <Input min={1} max={maxDay} name="day" {...inputProps} />;
};

DayInput.propTypes = {
  month: PropTypes.number,
  year: PropTypes.number,
};

export default DayInput;
