import { CaptchaFormGroup as CaptchaFormGroupCore } from 'components/objects/forms/formGroups';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { CaptchaRefContext } from '../captcha';
import { useContext } from 'react';

const CaptchaFormGroup = ({ fieldName }) => {
  const captchaRef = useContext(CaptchaRefContext);

  const formik = useFormikContext();
  const formName = formik.status.formName;

  return <CaptchaFormGroupCore ref={captchaRef} fieldName={fieldName} captchaId={formName} />;
};

CaptchaFormGroup.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default CaptchaFormGroup;