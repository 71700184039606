import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { Input } from 'components/primitives/form';
import htmlSafeHandlers from './htmlSafeHandlers';

const HtmlSafeTextBoxField = props => {
  return (
    <Field
      type="text"
      as={Input}
      {...props}
      {...htmlSafeHandlers}
    />
  );
};

HtmlSafeTextBoxField.propTypes = fieldPropTypes;

export default HtmlSafeTextBoxField;
