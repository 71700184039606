import { useSimpleTexts } from 'components/sanaText';
import { useSelector } from 'react-redux';

export function useBooleanOptions(fieldName) {
  if (useSelector(state => state.insiteEditor.initialized))
    return useEditableBooleadOptions(fieldName);

  const optionsKey = getBooleanOptionsKey(fieldName);
  const [yesText, noText, defaultYesText, defaultNoText] = useSimpleTexts([
    optionsKey + '_Yes',
    optionsKey + '_No',
    'Yes',
    'No',
  ]).texts;

  const options = [
    { name: yesText || defaultYesText, value: true },
    { name: noText || defaultNoText, value: false },
  ];

  return options;
}

function useEditableBooleadOptions(fieldName) {
  const defaultTextsToLoad = ['Yes', 'No'];
  defaultTextsToLoad.disableInsiteEditor = true;
  const loadedDefaultTexts = useSimpleTexts(defaultTextsToLoad).texts;

  const optionsKey = getBooleanOptionsKey(fieldName);
  const textsToLoad = [optionsKey + '_Yes', optionsKey + '_No'];
  textsToLoad.insiteEditorPostLoad = sanaTexts => ({
    ...sanaTexts,
    texts: sanaTexts.texts.map((text, index) => !text && loadedDefaultTexts[index] || text),
  });

  const [yesText, noText] = useSimpleTexts(textsToLoad).texts;
  return [
    { name: yesText, value: true },
    { name: noText, value: false },
  ];
}

function getBooleanOptionsKey(fieldName) {
  return `OptionText_${fieldName?.replace(/\./, '_')}`;
}
