import styles from './RadioButtonGroup.module.scss';
import type { ReactNode } from 'react';
import Radio, { Props as RadioProps } from './Radio';

type Props = {
  id: string;
  value?: string | null;
  items?: Array<{
    name: ReactNode;
    value: string;
  }> | null;
  onChange: (value: string) => void;
} & Omit<RadioProps, 'key' | 'id' | 'value' | 'onChange' | 'checked' | 'className'>;

const RadioButtonGroup = ({ id, value: selectedValue, items, onChange, ...attributes }: Props) => {
  if (!items?.length)
    return null;

  return items.map(({ name, value }) => {
    const radioButtonId = `${id}_${value}`;

    return (
      <Radio
        key={radioButtonId}
        id={radioButtonId}
        value={value}
        onChange={() => onChange(value)}
        {...attributes}
        checked={selectedValue === value}
        className={styles.item}
      >
        {name}
      </Radio>
    );
  });
};

export default RadioButtonGroup;
