import styles from '../SanaForm.module.scss';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import FormGroupTemplate from './FormGroupTemplate';
import { createLabelId } from '../helpers';

const ReadOnlyFormGroup = ({
  fieldName,
  fieldTitle,
  displayValue,
  descriptionComponent: DescriptionComponent,
  description,
}) => {
  const formik = useFormikContext();
  const labelId = createLabelId(formik.status.formName, fieldName);

  return (
    <FormGroupTemplate
      label={<span id={labelId}>{fieldTitle}</span>}
      field={displayValue}
      description={DescriptionComponent ? <DescriptionComponent>{description}</DescriptionComponent> : description}
      aria-labelledby={labelId}
      className={styles.readOnly}
    />
  );
};

ReadOnlyFormGroup.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldTitle: PropTypes.node,
  displayValue: PropTypes.node.isRequired,
  descriptionComponent: PropTypes.elementType,
  description: PropTypes.string,
};

export default ReadOnlyFormGroup;