import { getDaysInMonth } from '@wojtekmaj/date-utils';

export function getMaxDay(month: number | null, year: number | null): number {
  if (!month)
    return 31;

  /* In case month present and year is not present day should be calculated using any non-leap year value. Original function implementation
   from react-date-picker library passed `null` to Date constructor which resulted in date set in the year of 1900. As of now, null can not
   be passed because of Typescript Date constructor type definitions and in any way it is not logical, so hardcoded year value is used. */
  return getDaysInMonth(new Date(year || 1900, month - 1, 1));
}

export function getYearDiff(culture: string): number {
  return culture.includes('th-TH') ? 543 : 0;
}
