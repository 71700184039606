import { getIn } from 'formik';
import { FileGroup } from 'utils/api/files';

const escapeMap = {
  '|': '||',
  '[': '|_',
  ']': '|-',
};
const escapeRegex = /[|[\]]/g;

export function escapeFieldName(text) {
  if (!text)
    return text;

  return text.replace(escapeRegex, match => escapeMap[match]);
}

export function extractEscapedTemplateFields(formData, templateFields) {
  const result = {};
  for (const field of templateFields) {
    result[field.name] = getIn(formData, escapeFieldName(field.name));
  }
  return result;
}

export function extractEscapedTemplateFieldsAndFiles(formData, templateFields) {
  const fields = {};
  const files = [];

  for (const field of templateFields) {
    if (field.editor === 'FILE') {
      const fileData = getIn(formData, escapeFieldName(field.name));

      if (fileData instanceof FileList)
        files.push(new FileGroup(field.name, fileData));
      else if (fileData instanceof File)
        files.push(new FileGroup(field.name, [fileData]));
    } else {
      fields[field.name] = getIn(formData, escapeFieldName(field.name));
    }
  }

  return { fields, files };
}