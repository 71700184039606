import { useCallback } from 'react';
import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { FileInput } from 'components/primitives/form';

const componentBuilder = ({ field: { name, value, ...field }, form: { setFieldValue, setFieldTouched }, ...props }) => {
  const onChange = useCallback(value => {
    setFieldTouched(name, true, false);
    setFieldValue(name, value, true);
  }, [name, setFieldValue, setFieldTouched]);

  return <FileInput {...field} {...props} name={name} value={value || null} onChange={onChange} />;
};

// eslint-disable-next-line react/no-multi-comp
const FileField = props => (
  <Field
    component={componentBuilder}
    {...props}
  />
);

FileField.propTypes = fieldPropTypes;

export default FileField;
