import DateOnly from 'date-only';

export default context => value => {
  const { minDate, maxDate } = context.params;
  if (!minDate || !maxDate)
    return;

  const parsedValue = DateOnly.parse(value);
  if (!parsedValue)
    return;

  if (DateOnly.compare(minDate, parsedValue) > 0 || DateOnly.compare(maxDate, parsedValue) < 0)
    return context.errorMessage;
};
