import { getYear } from '@wojtekmaj/date-utils';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Input from './Input';
import { isMaxDate, isMinDate } from 'react-date-picker/dist/shared/propTypes';

export const defaultMinYear = 1000;
export const defaultMaxYear = 9999;

const YearInput = ({
  maxDate,
  minDate,
  placeholder = '----',
  value,
  yearDiff,
  ...inputProps
}) => {
  const { minYear, maxYear } = useMemo(() => {
    const minYear = Math.max(getYear(minDate) + yearDiff, defaultMinYear);
    const maxYear = Math.min(getYear(maxDate) + yearDiff, defaultMaxYear);
    return { minYear, maxYear };
  }, [minDate, maxDate, yearDiff]);

  return (
    <Input
      min={minYear}
      max={maxYear}
      name="year"
      placeholder={placeholder}
      value={value && value + yearDiff}
      {...inputProps}
    />
  );
};

YearInput.propTypes = {
  maxDate: isMaxDate,
  minDate: isMinDate,
  placeholder: PropTypes.string,
  value: PropTypes.number,
  yearDiff: PropTypes.number,
};

export default YearInput;
