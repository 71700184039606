import PropTypes from 'prop-types';
import { ReadOnlyFormGroup } from '../../formGroups';

const CountryReadOnlyFormGroup = ({ displayValue, initialValue, fieldName, items, ...props }) => {
  const countryId = initialValue || displayValue,
    country = items && items.find(country => country.value === countryId);

  return (
    <ReadOnlyFormGroup
      fieldName={fieldName}
      displayValue={country && country.name || countryId}
      {...props}
    />
  );
};

CountryReadOnlyFormGroup.propTypes = {
  displayValue: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
};

export default CountryReadOnlyFormGroup;
