import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';
import { useCountryField } from '../hooks';
import { ReadOnlyFormGroup } from 'components/objects/forms/formGroups';

const StreetNoReadOnlyFormGroup = ({ availableCountryIds, ...props }) => {
  const countryField = useCountryField();
  const countryFieldName = countryField?.name;
  const { values } = useFormikContext();
  const selectedCountryId = countryFieldName ? getIn(values, countryFieldName) : null;
  
  if (!isAvailable(selectedCountryId, availableCountryIds))
    return null;

  return <ReadOnlyFormGroup {...props} />;
};

StreetNoReadOnlyFormGroup.propTypes = {
  availableCountryIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default StreetNoReadOnlyFormGroup;

function isAvailable(selectedCountryId, availableCountryIds) {
  return !availableCountryIds.length || selectedCountryId && availableCountryIds.includes(selectedCountryId);
}