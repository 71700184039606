import { formatText } from 'utils/formatting';
import { getPerCountryValidator } from './utils';

export default context => (value, _fieldName, formikContext) => {
  const { countryFieldName, perCountryPatterns } = context.params;

  if (!value || !perCountryPatterns || !perCountryPatterns.length)
    return;

  const perCountryValidator = getPerCountryValidator(perCountryPatterns, countryFieldName, formikContext.values, context.params.countryId);

  if (perCountryValidator == null)
    return;

  const patternEntries = perCountryValidator.patterns;
  for (const { pattern } of patternEntries) {
    if (new RegExp(pattern).test(value))
      return;
  }

  return formatText(context.errorMessage, [
    context.params.fieldTitle,
    patternEntries.map(e => e.mask).join(', '),
  ]);
};