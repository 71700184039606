import CaptchaRefContext from './CaptchaRefContext';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const CaptchaRef = forwardRef(({ children }, ref) => (
  <CaptchaRefContext.Provider value={ref}>
    {children}
  </CaptchaRefContext.Provider>
));

CaptchaRef.propTypes = {
  children: PropTypes.node,
};

export default CaptchaRef;
