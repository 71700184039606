import type { CommonProps, Item } from './types';
import { useMemo } from 'react';
import Select from '../Select';

type Props = CommonProps & {
  minYear: number;
  maxYear: number;
};

const YearSelect = ({
  id,
  name,
  placeholderText,
  minYear,
  maxYear,
  ...selectProps
}: Props) => {
  const items = useMemo(() => {
    const items: Array<Item> = [{ name: placeholderText, value: null }];
    for (let i = maxYear; i >= minYear; i--)
      items.push({ name: i.toString(), value: i });

    return items;
  }, [placeholderText, minYear, maxYear]);

  return (
    <Select
      items={items}
      id={`${id}_year`}
      name={name && `${name}_year`}
      {...selectProps}
    />
  );
};

export default YearSelect;
