export const ValidationTexts = Object.freeze({
  Required: 'Validation_RequiredField',
  RequiredPlural: 'Validation_RequiredFieldPlural',
  RequiredCaptcha: 'Validation_Captcha_Required',
  Invalid: 'Validation_InvalidField',
  InvalidReadableField: 'Validation_InvalidReadableField',
  MaxLengthExceeded: 'Validation_MaxLengthExceeded',
  OutOfRange: 'Validation_OutOfRange',
  Range: 'Validation_Range',
  Precise: 'Validation_SumValue',
  MaxLength: 'Validation_MaxLength',
  PasswordStrength: 'Validation_PasswordStrength',
  UniqueEmail: 'CreateAccount_ExistingShopAccountEmail',
  PerCountryPatterns: 'Validation_GenericPatterns',
  UniqueOrderTemplate: 'OrderTemplate_SaveFailedInUse',
  MinAge: 'Validation_DatePicker_MinAgeMessage',
  FileAcceptedTypes: 'FileUploader_AcceptValidation',
  FileNameMaxLength: 'FileUploader_MaxNameLengthValidation',
  FilesMaxSize: 'FileUploader_MaxSizeValidation',
  DatesRange: 'Validation_DatePicker_RangeMessage',
});

export const validationTextKeys = Object.values(ValidationTexts);
