import styles from './TextArea.module.scss';

export type Props = {
  className?: string | null;
} & Omit<JSX.IntrinsicElements['textarea'], 'className'>;

const TextArea = ({ className, rows = 2, cols = 20, value, ...attributes }: Props) => (
  <div className={`${styles.tar} ${className || ''}`}>
    <textarea rows={rows} cols={cols} value={value === null ? '' : value} {...attributes} />
  </div>
);

export default TextArea;
