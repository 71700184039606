export default {
  onBeforeInput(e: InputEvent) {
    const key = e.data;
    if (!key || key === 'Unidentified') {
      const input = e.target as HTMLInputElement;
      setTimeout(() => cleanNotSafeChars(input));
    }

    if (key === '<' || key === '>')
      e.preventDefault();

    // Prevent '&#'.
    if (key === '#') {
      const input = e.target as HTMLInputElement;
      const value = input.value;
      if (value && value[(input.selectionStart || value.length) - 1] === '&')
        e.preventDefault();
    }
  },
  onPaste(e: ClipboardEvent) {
    const input = e.target as HTMLInputElement;
    setTimeout(() => cleanNotSafeChars(input));
  },
};

const cleanNotSafeChars = (input: HTMLInputElement) => {
  const value = input.value,
    newValue = value.replace(/[<>]|&#/g, '');

  if (newValue !== value)
    setValue!(input, newValue);
};

const setValue = (function () {
  if (typeof window === 'undefined')
    return null;

  const proto = window.HTMLInputElement.prototype;
  const nativeInputValueSetter = (Object.getOwnPropertyDescriptor(proto, 'value')!).set;

  return nativeInputValueSetter && ((input: HTMLInputElement, value: string) => {
    // Setter .value= is not working as we wanted because React library overrides input value setter. https://stackoverflow.com/a/46012210
    nativeInputValueSetter.call(input, value);
    input.dispatchEvent(new CustomEvent('change', { bubbles: true }));
  });
})();