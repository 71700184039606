import styles from './DescriptionMessage.module.scss';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';

const DescriptionMessage = ({ children }) => (
  <div className={styles.descriptionMessage}>
    <span className={styles.icon}><FontAwesomeIcon icon={faInfo} /></span>
    {children}
  </div>
);

DescriptionMessage.propTypes = {
  children: PropTypes.node,
};

export default DescriptionMessage;
