import { useCallback } from 'react';
import useFormLocker from './useFormLocker';

export default function useFormLockerOnSubmit(onSubmit, formLockKey, dependencies) {
  const isLocked = useFormLocker(formLockKey);

  return useCallback((...args) => {
    if (isLocked())
      return;

    onSubmit(...args);
  }, dependencies);
}
