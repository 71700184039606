import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { Input } from 'components/primitives/form';

const TextBoxField = props => {
  return (
    <Field
      type="text"
      as={Input}
      {...props}
    />
  );
};

TextBoxField.propTypes = fieldPropTypes;

export default TextBoxField;
