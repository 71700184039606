import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { Input } from 'components/primitives/form';

const PasswordField = props => (
  <Field
    type="password"
    autoComplete="off"
    as={Input}
    {...props}
  />
);

PasswordField.propTypes = fieldPropTypes;

export default PasswordField;
