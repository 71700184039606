import { useInsiteEditorTexts } from 'components/visualDesigner/insiteEditor';
import { useSelector } from 'react-redux';

const useEditableTemplateFields = templateFields => {
  if (!useSelector(state => state.insiteEditor.initialized))
    return templateFields;

  const getTemplateFieldKey = textKey => `EntityField_${textKey?.replace(/\./, '_')}`;
  const getTemplateFieldDescriptionKey = textKey => `EntityField_Description_${textKey?.replace(/\./, '_')}`;

  return useInsiteEditorTexts(templateFields, (field, getText) => ({
    ...field,
    title: getText(getTemplateFieldKey(field.name), field.title),
    description: getText(getTemplateFieldDescriptionKey(field.name), field.description ?? undefined),
  }), { textOnly: true, isConstructedTexts: true });
};

export default useEditableTemplateFields;