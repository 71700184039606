import { useRef, useCallback } from 'react';
import useFormLocker from './useFormLocker';

export default function useCaptchaOnSubmit(onSubmit, formLockKey, dependencies) {
  const captchaRef = useRef();
  const isLocked = useFormLocker(formLockKey);

  return {
    onSubmit: useCallback((...args) => {
      const captcha = captchaRef.current;

      if (captcha && !captcha.getValue())
        captcha.execute(); // Evaluate invisible captcha. Subsequent form submission will be triggered when a token is obtained.
      else if (!isLocked())
        onSubmit(...args);
    }, dependencies),
    captchaRef,
  };
}
