import type { CommonProps, Item } from './types';
import { useMemo } from 'react';
import Select from '../Select';
import { pad } from 'utils/helpers';

const MonthSelect = ({
  id,
  name,
  placeholderText,
  ...selectProps
}: CommonProps) => {
  const items = useMemo(() => {
    const items: Array<Item> = [{ name: placeholderText, value: null }];
    for (let i = 1; i <= 12; i++)
      items.push({ name: pad(i, 2), value: i });

    return items;
  }, [placeholderText]);

  return (
    <Select
      items={items}
      id={`${id}_month`}
      name={name && `${name}_month`}
      {...selectProps}
    />
  );
};

export default MonthSelect;
